import React, { FunctionComponent, useState } from 'react'
import { MarkdownContent } from '../../../shared/MarkdownContent'
import { OpenAPIV3_1 } from 'openapi-types'
import { MinusIcon, PlusIcon } from '../../../icons'
import { AnimatePresence, motion } from 'framer-motion'
import { ApiExample } from './ApiExample'
import { expandAnimation } from '../../../shared/animationVariants'

type Props = {
  schema: OpenAPIV3_1.SchemaObject
  name?: string
  children?: React.ReactNode
}

export const ApiSchemaItem: FunctionComponent<Props> = ({ schema, name, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { type, format, required, description, example, title, deprecated } = schema

  const supportedProperties = [
    'type',
    'title',
    'format',
    'required',
    'description',
    'example',
    'oneOf',
    'allOf',
    'anyOf',
    'enum',
    'properties',
    'items',
    'deprecated'
  ]
  const unSupportedProperties = Object.keys(schema).filter(key => supportedProperties.indexOf(key) === -1)

  const enumValues = schema.enum

  const bgColor = isOpen ? 'bg-fog' : 'bg-grey-light'

  const ButtonOrDivContainer: FunctionComponent<{ children?: React.ReactNode }> = props => {
    return children ? (
      <div className={`${bgColor} p-sm ${isOpen ? 'rounded-t-half' : 'rounded-half'}`}>
        <button
          aria-label={`Expand ${name || title} ${format || type}`}
          className="w-full text-left"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="flex items-start justify-between">
            {props.children}

            {isOpen ? <MinusIcon width="1.5rem" height="1.5rem" /> : <PlusIcon width="1.5rem" height="1.5rem" />}
          </span>
        </button>
      </div>
    ) : (
      <div className={`flex justify-between ${bgColor} p-sm rounded-half`}>{props.children}</div>
    )
  }

  return (
    <li
      aria-label={name || title}
      className={`[&:not(:first-child)]:my-md [&:last-child]:mb-0 border-2 ${
        isOpen ? 'border-fog rounded-half' : 'border-transparent'
      }`}
    >
      <ButtonOrDivContainer>
        <div className="m-0 flex flex-col space-y-sm">
          <div className="flex flex-wrap items-center space-x-sm">
            {(name || title) && (
              <pre className={`font-bold ${deprecated ? 'line-through' : ''}`}>
                {name || <span className="capitalize">{title}</span>}
              </pre>
            )}

            {schema.oneOf ? (
              <pre className="p-xs text-sm font-bold bg-fog rounded-half">
                oneOf: {schema.oneOf.map((obj: OpenAPIV3_1.SchemaObject) => obj.title).join(' | ')}
              </pre>
            ) : schema.anyOf ? (
              <pre className="p-xs text-sm font-bold bg-fog rounded-half">
                anyOf: {schema.anyOf.map((obj: OpenAPIV3_1.SchemaObject) => obj.title).join(' | ')}
              </pre>
            ) : (
              <pre className="p-xs text-sm font-bold bg-fog rounded-half">
                {format ? format : type === 'array' ? `${format || type}[]` : title ? title : type}
              </pre>
            )}

            {deprecated && <div className="tag tag-orange">deprecated</div>}

            {required && <div className="text-sm font-bold text-red">required</div>}
          </div>

          {description && (
            <div className="prose">
              <MarkdownContent markdown={description} />
            </div>
          )}

          {enumValues && (
            <div className="pt-sm">
              <div className="mr-xs mb-xs">Possible enum values:</div>
              <div className="flex flex-wrap items-center gap-sm">
                {enumValues.map((enumVal: string) => (
                  <pre key={`enum-${enumVal}`} className="p-xs text-sm font-bold bg-fog rounded-half inline">
                    {enumVal}
                  </pre>
                ))}
              </div>
            </div>
          )}

          {unSupportedProperties.length > 0 ? (
            <div className="flex flex-col space-y-xs">
              {unSupportedProperties.map(key => (
                <>
                  <pre key={`unsupported-${key}`}>
                    {key}: {JSON.stringify(schema[key as keyof OpenAPIV3_1.BaseSchemaObject], null, 2)}
                  </pre>
                </>
              ))}
            </div>
          ) : null}

          {!children && example ? (
            <div className="flex items-center">
              <span className="mr-xs">Example:</span>
              <pre className="p-xs text-sm font-bold bg-fog rounded-half inline">{example}</pre>
            </div>
          ) : null}
        </div>
      </ButtonOrDivContainer>

      <div className={`${isOpen ? 'rounded-b-half p-sm' : ''}`}>
        <AnimatePresence>
          {isOpen ? (
            <motion.div {...expandAnimation}>
              {children}

              <ApiExample example={example} />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </li>
  )
}
