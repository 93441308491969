import React, { FunctionComponent } from 'react'
import { OpenAPIV3_1 } from 'openapi-types'
import { ErrorBoundary } from '../../../shared/ErrorBoundary'
import { ApiSchemaItem } from './ApiSchemaItem'

type Props = {
  parameters: OpenAPIV3_1.ParameterObject[]
}

export const ApiParameters: FunctionComponent<Props> = ({ parameters }) => {
  if (!parameters) {
    return null
  }

  const params = parameters as OpenAPIV3_1.ParameterObject[]

  const uniqueParamLocations = Array.from(new Set([...params.map(param => param.in)]))

  const paramsByLocation: { [key: string]: OpenAPIV3_1.ParameterObject[] } = uniqueParamLocations.reduce(
    (acc: { [key: string]: OpenAPIV3_1.ParameterObject[] }, location) => {
      const paramsForLocation = params.filter(p => p.in === location)
      return { ...acc, [location]: [...paramsForLocation] }
    },
    {}
  )

  return (
    <ErrorBoundary>
      {uniqueParamLocations.map((location, i) => (
        <section key={`${location}-${i}`}>
          <h3 className="text-lg mb-sm">
            <span className="capitalize">{location}</span> parameters
          </h3>

          <ul className="list-none">
            {paramsByLocation[location].map((parameter: OpenAPIV3_1.ParameterObject, i: number) => {
              const schema = parameter.schema as OpenAPIV3_1.SchemaObject
              const required = parameter.required ? [parameter.name] : undefined
              const example = parameter.example

              return (
                <ApiSchemaItem
                  key={`p-${i}`}
                  name={parameter.name}
                  schema={{ ...schema, description: parameter.description, example, required }}
                />
              )
            })}
          </ul>
        </section>
      ))}
    </ErrorBoundary>
  )
}
