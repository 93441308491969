import React from 'react'
import { Form, useLoaderData, useNavigation, useParams, useRouteLoaderData, useSearchParams } from 'react-router-dom'
import { Webhook, WebhookEventHistoryType } from '../../../../../types'
import { WebhookHistoryEvent } from './WebhookHistoryEvent'
import { PaginatedContent } from '../../../../shared/PaginatedContent'
import { WebhookEventSkeleton } from './WebhookEventSkeleton'
import { ampli } from '../../../../../ampli'

export const WebhookHistory = () => {
  const { environment, webhookId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { location } = useNavigation()
  const { active } = useRouteLoaderData('webhook') as Webhook
  const { events, next } = useLoaderData() as { events: WebhookEventHistoryType[]; next: string | null }

  const hasEvents = events?.length > 0
  const filter = searchParams.get('status')
  const currentPage = searchParams.get('next')

  const isLastPage = Boolean(currentPage && !hasEvents)

  return (
    <div className="card p-lg">
      {!hasEvents && !active ? (
        <Form
          method="post"
          action={`/account/${environment}/webhooks/${webhookId}`}
          className="mt-xl flex justify-center"
        >
          <button type="submit" className="btn btn-primary-dark">
            Activate webhook to receive events
          </button>
        </Form>
      ) : !hasEvents && !filter ? (
        <div className="text-center">No events, yet</div>
      ) : (
        <PaginatedContent showPaginationLinks={hasEvents || isLastPage} next={next}>
          <div className="flex flex-col ml-sm">
            <div className="flex items-end justify-between mb-sm">
              <h2 className="text-md w-2/3 capitalize">{filter} Events</h2>

              <select
                aria-label="Filter by status"
                className="select w-1/3"
                defaultValue={filter as string}
                onChange={({ target }) => {
                  ampli.filterWebhookEvents({ status: target.value })
                  setSearchParams(target.value ? { status: target.value } : {})
                }}
              >
                <option value="">All events</option>
                <option value="delivered">Delivered</option>
                <option value="retrying">Retrying</option>
                <option value="failed">Failed</option>
              </select>
            </div>

            {location?.search ? (
              <WebhookEventSkeleton />
            ) : isLastPage ? (
              <div className="text-center font-bold text-storm mt-md">No more events with status {filter}</div>
            ) : !hasEvents ? (
              <div className="text-center font-bold text-storm mt-md">No events with status {filter}</div>
            ) : (
              events.map(event => <WebhookHistoryEvent key={`ev-${event.eventId}`} event={event} />)
            )}
          </div>
        </PaginatedContent>
      )}
    </div>
  )
}
