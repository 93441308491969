import React, { FunctionComponent, ReactNode } from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from '../icons'
import { useSearchParams } from 'react-router-dom'

type Props = {
  children: ReactNode
  next: string | null
  showPaginationLinks: boolean
}

export const PaginatedContent: FunctionComponent<Props> = ({ children, next, showPaginationLinks }) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const existingParams = Object.fromEntries(searchParams)
  const prev = existingParams.next

  const goToNextPage = () => {
    setSearchParams({ ...existingParams, next: next as string })
  }

  const goToPrevPage = () => {
    setSearchParams({ ...existingParams, next: prev as string })
  }

  return (
    <>
      {children}

      {showPaginationLinks && (
        <div className="flex justify-center mt-md">
          {prev && (
            <button onClick={goToPrevPage}>
              <span className="flex items-center space-x-xs mr-sm">
                <ArrowLeftIcon width="1.1rem" height="1.1rem" color="var(--colors-blue-dark)" title="" />
                <span className="link">Previous page</span>
              </span>
            </button>
          )}

          {next && (
            <button onClick={goToNextPage}>
              <span className="flex items-center space-x-xs ml-sm">
                <span className="link">Next page</span>
                <ArrowRightIcon width="1.1rem" height="1.1rem" color="var(--colors-blue-dark)" title="" />
              </span>
            </button>
          )}
        </div>
      )}
    </>
  )
}
